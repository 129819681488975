import { createStore } from "vuex";
import authTracking from "./modules/auth-tracking";

import {
  apiURL
} from "@/api/allApis"

import {
  Shield
} from 'shield-js-npm';

export default createStore({
  state: {
    prizeClaimBankDetails: null,
    prizeClaimAllBankDetails: null,
    prizeClaimAllPaypalDetails: null,
    paddressDetails: null,
    prizeClaimPaypalDetails: null,
    addBankDetails: null,
    update_bank_data: null,
    addAddressDetails: null,
    fetchAccountData: null,
    fetchCartCountData: null,
    delete_address_details: null,
    update_summary_address: null,
    bankDeleteResponse: null,
    paypalDeleteResponse: null,
    tokenValidationData: null,
    signUpBonusData: null,
    PrizeWonData: null,
    RaffleWinnerData: null,
    raisedCharityData: null,
    cartItemsWithLogin: null,
    cart: JSON.parse(sessionStorage.getItem('cartVal')) || [],
    raffle: JSON.parse(localStorage.getItem('raffle')) || null,
    cartTickets: JSON.parse(localStorage.getItem('cartTickets')) || null,
    sessionId: null,
    deviceIntelligence: null,
  },

  getters: {
    getRaffle: state => state.raffle,
    getCartTickets: state => state.cartTickets,
    getCartItemsWithLogin: (state) => {
      console.log("ww1", state.cartItemsWithLogin);
      return state.cartItemsWithLogin;
    },
    guestCartCount: (state) => {
      const uniqueRaffleCodes = new Set(state.cart.map(item => item.RaffleCode));
      const result = [...uniqueRaffleCodes];
      return result.length;
    },

    getValidateTokenData(state) {
      return state.tokenValidationData;
    },
    getSignUpBonusData(state) {
      return state.signUpBonusData;
    },

    getPrizeClaimBankDetails: (state) => {
      return state.prizeClaimBankDetails;
    },
    getPrizeClaimAllBankDetails: (state) => {
      return state.prizeClaimAllBankDetails;
    },
    getPrizeClaimPaypalDetails: (state) => {
      return state.prizeClaimPaypalDetails;
    },
    getPrizeClaimAllPaypalDetails: (state) => {
      return state.prizeClaimAllPaypalDetails;
    },
    getAddressDetails: (state) => {
      return state.paddressDetails;
    },
    getAddBankDetails: (state) => {
      return state.addBankDetails;
    },
    getUpdateBankDetails: (state) => {
      return state.update_bank_data;
    },
    getaddAddressDetails: (state) => {
      return state.addAddressDetails;
    },

    getAccountDetails: (state) => {
      return state.fetchAccountData;
    },
    getcartCount: (state) => {
      return state.fetchCartCountData;
    },

    getdeleteAddressDetails: (state) => {
      return state.delete_address_details;
    },
    getUpdateAddressDetails: (state) => {
      return state.update_summary_address;
    },
    //for delete bank
    getBankResponse(state) {
      return state.bankDeleteResponse;
    },
    //for delete paypal
    getPaypalResponse(state) {
      return state.paypalDeleteResponse;
    },
    getPrizeWon: (state) => {
      return state.PrizeWonData;
    },
    getRaffleWinner: (state) => {
      return state.RaffleWinnerData;
    },
    getRaisedCharity: (state) => {
      return state.raisedCharityData;
    },
    getSessionId: (state) =>{
      return state.sessionId
    },
    getDeviceIntelligence: (state) =>{
      return state.deviceIntelligence
    },

  },

  mutations: {
    setCartItemsWithLogin(state, cartItems) {
      console.log({ cartItems });
      state.cartItemsWithLogin = cartItems;
      console.log(state.cartItemsWithLogin);
    },
    setRaffle(state, raffle) {
      state.raffle = raffle;
      localStorage.setItem('raffle', JSON.stringify(raffle));
    },
    setCartTickets(state, cartTickets) {
      state.cartTickets = cartTickets;
      localStorage.setItem('cartTickets', JSON.stringify(cartTickets));
    },
    set_validate_Token_data(state, data) {
      state.tokenValidationData = data;
    },
    set_signUp_Bonus_data(state, data) {
      state.signUpBonusData = data;
    },
    set_fetch_cart_data(state, data) {
      state.fetchCartCountData = data;
    },
    set_fetch_account_data(state, data) {
      state.fetchAccountData = data;
    },

    setPrizeClaimBankDetails(state, data) {
      state.prizeClaimBankDetails = data;
    },
    setPrizeClaimAllBankDetails(state, data) {
      state.prizeClaimAllBankDetails = data;
    },
    setPrizeClaimPaypalDetails(state, data) {
      state.prizeClaimPaypalDetails = data;
    },
    setPrizeClaimAllPaypalDetails(state, data) {
      state.prizeClaimAllPaypalDetails = data;
    },
    //for bank delete
    setBankDeleteResponse(state, data) {
      state.bankDeleteResponse = data;
    },
    //for paypal delete
    setPaypalDeleteResponse(state, data) {
      state.paypalDeleteResponse = data;
    },
    setAddressDetails(state, data) {
      state.paddressDetails = data;
    },
    setAddBankDetails(state, data) {
      state.addBankDetails = data;
    },
    setUpdateBank(state, data) {
      state.update_bank_data = data;
    },
    setaddAddressDetails(state, data) {
      state.addAddressDetails = data;
    },
    delete_bank_details(state, bankId) {
      state.prizeClaimBankDetails = state.prizeClaimBankDetails.filter(
        (bank) => bank.Id !== bankId
      );
    },
    setDeleteAddrDetails(state, data) {
      state.delete_address_details = data;
    },
    setUpdateAddress(state, data) {
      state.update_summary_address = data;
    },
    // delete_address_details(state, addressId) {
    //   state.paddressDetails = state.paddressDetails.filter(
    //     (address) => address.Id !== addressId
    //   );
    // },
    addBankDetails(state, bankDetails) {
      state.prizeClaimBankDetails.push(bankDetails);
    },
    addPaypalDetails(state, paypalDetails) {
      state.prizeClaimPaypalDetails.push(paypalDetails);
    },
    addAddressDetails(state, addressDetails) {
      state.paddressDetails.push(addressDetails);
    },

    update_summary_address(state, addressDetails) {
      state.paddressDetails.push(addressDetails);
    },
    update_paypal_data(state, payEmail) {
      state.prizeClaimPaypalDetails.push(payEmail);
    },
    update_bank_data(state, bankDetails) {
      state.prizeClaimBankDetails.push(bankDetails);
    },
    setPrizeWon(state, data) {
      state.PrizeWonData = data;
    },
    setRaffleWinner(state, data) {
      state.RaffleWinnerData = data;
    },
    setRaisedCharity(state, data) {
      state.raisedCharityData = data;
    },

    updateCart(state, cart) {
      // Ensure that cart is a flat array
      state.cart = Array.isArray(cart) ? cart.flat(Infinity) : [];
      sessionStorage.setItem('cartVal', JSON.stringify(state.cart));
    },

    removeRaffleCode(state, raffleCode) {
      const newCart = state.cart.filter(item => item.RaffleCode !== raffleCode);
      state.cart = newCart;
    },
    resetCartCount(state) {
      state.cart = [];
    },

    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    setDeviceIntelligence(state, deviceIntelligence) {
      state.deviceIntelligence = deviceIntelligence;
    },

  },



  actions: {
    storeRaffle({ commit }, raffle) {
      commit('setRaffle', raffle);
    },
    storeCartTickets({ commit }, cartTickets) {
      commit('setCartTickets', cartTickets);
    },
    storeCartItemsWithLogin({ commit }, cartItems) {
      console.log({ cartItems });
      setTimeout(() => {
        commit('setCartItemsWithLogin', cartItems);
      }, 1000); 
    },
    addToCart({ commit, state }, newItem) {
      const existingItemIndex = state.cart.findIndex(item => item.id === newItem.id);

      if (existingItemIndex !== -1) {

        const updatedItem = { ...state.cart[existingItemIndex] };
        updatedItem.entryValue = updatedItem.entryValue || 0;
        updatedItem.entryValue += newItem.entryValue;

        const updatedCart = [...state.cart];
        updatedCart[existingItemIndex] = updatedItem;

        commit('updateCart', updatedCart);
      } else {

        const updatedCart = [...state.cart, newItem];
        commit('updateCart', updatedCart);
      }

    },


    deleteRaffleCode({ commit, state }, raffleCode) {
      const newCart = state.cart.filter(item => item.RaffleCode !== raffleCode);
      commit('updateCart', newCart);
    },

    clearCart({ commit }) {
      commit('resetCartCount');
    },

    async fetch_prizes_won({ commit }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/fetchPrizesWon",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              // Authorization: JSON.parse(userSession),
            },
          }
        );
        const data = await response.json();
        commit("setPrizeWon", data);
      } catch (error) { }
    },

    async fetch_raffle_winner({ commit }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/fetchRaffleWinners",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              // Authorization: JSON.parse(userSession),
            },
          }
        );
        const data = await response.json();
        commit("setRaffleWinner", data);
      } catch (error) { }
    },

    async fetch_raised_charity({ commit }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/fetchRaisedCharity",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              // Authorization: JSON.parse(userSession),
            },
          }
        );
        const data = await response.json();
        commit("setRaisedCharity", data);
      } catch (error) { }
    },

    async display_bank_details({ commit }, userSession) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/fetchBankDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
          }
        );
        const data = await response.json();
        // console.log("bankdata:", JSON.stringify(data));
        commit("setPrizeClaimAllBankDetails", data);
        // Commit the mutation to update the state with the bank details
        commit("setPrizeClaimBankDetails", data.slice(0, 2));
      } catch (error) { }
    },
    async addBankDetails({ commit }, { userSession, bankDetails }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/addBankDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(bankDetails),
          }
        );
        const data = await response.json();
        // console.log("addClaimBankDetails:", data);

        commit("setAddBankDetails", data);
        return data;
      } catch (error) {
        //  console.log("Error adding bank details:", error);
        throw new Error("Account number is alredy added");
      }
    },
    async update_summary_address({ commit }, { userSession, addressDetails }) {
      //alert(1);
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/updateAddressDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(addressDetails),
          }
        );
        const data = await response.json();
        // console.log("addressUpdatedata", data);
        commit("setUpdateAddress", data);
      } catch (error) {
        //  console.error("Error updating address:", error);
      }
    },
    async delete_bank_details({ commit }, { userSession, bankId }) {
      //  console.log({ bankId });
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/deleteBankDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify({
              bankId: bankId,
            }),
          }
        );
        const data = await response.json();
        //   console.log("datadatadata", data);
        // Commit the mutation to remove the bank details from the store
        commit("delete_bank_details", bankId);
        commit("setBankDeleteResponse", data); //delete bank response
        // return data;
      } catch (error) {
        //  console.log("Error deleting bank details:", error);
      }
    },
    async display_summary_address({ commit }, userSession) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/displayAddressWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
          }
        );

        const data = await response.json();
        //  alert(JSON.stringify(data));

        // Commit the mutation to update the state with the bank details
        commit("setAddressDetails", data.slice(0, 2));
      } catch (error) { }
    },
    async addAddressDetails({ commit }, { userSession, addressDetails }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/addAddressWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(addressDetails),
          }
        );
        const data = await response.json();

        commit("setaddAddressDetails", data);
      } catch (error) { }
    },
    async delete_address_details({ commit }, { userSession, delAddr }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/deleteAddressDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(delAddr),
          }
        );
        const data = await response.json();
        // console.log("addressdata", data);
        // Commit the mutation to remove the bank details from the store
        commit("setDeleteAddrDetails", data);
      } catch (error) { }
    },
    async display_paypal_details({ commit }, userSession) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/fetchPaypalDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
          }
        );

        const data = await response.json();
        // console.log("paypaldata:", JSON.stringify(data));
        commit("setPrizeClaimAllPaypalDetails", data);
        // Commit the mutation to update the state with the bank details
        commit("setPrizeClaimPaypalDetails", data.slice(0, 2));
      } catch (error) { }
    },
    async addPaypalDetails({ commit }, { userSession, paypalDetails }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/addPaypalDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(paypalDetails),
          }
        );
        const data = await response.json();
        if (data.status === 200) {
          commit("addPaypalDetails", data);
        }
      } catch (error) { }
    },
    async update_paypal_data({ commit }, { userSession, payEmail }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/updatePaypalDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(payEmail),
          }
        );
        const data = await response.json();
        commit("update_paypal_data", data);
      } catch (error) { }
    },
    async update_bank_data({ commit }, { userSession, bankDetails }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/updateBankDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify(bankDetails),
          }
        );
        const data = await response.json();

        commit("setUpdateBank", data);
      } catch (error) { }
    },
    async delete_paypal_details(
      { commit },
      { userSession, paypalId, payPalEmail }
    ) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/deletePaypalDetailsWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
            body: JSON.stringify({
              payPalId: paypalId,
              mail_id: payPalEmail,
            }),
          }
        );
        const data = await response.json();
        // Commit the mutation to remove the bank details from the store
        commit("delete_paypal_details", data);
        commit("setPaypalDeleteResponse", data);
      } catch (error) { }
    },
    async fetchAccountDetails({ commit }, userSession) {
      try {
        const response = await fetch(apiURL.fetchAccountWithLogin, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: JSON.parse(userSession),
          },
        });
        const data = await response.json();
        commit("set_fetch_account_data", data.userprofile);
      } catch (error) { }
    },
    async fetchCartItems({ commit }, userSession) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/fetchCartCountWithLogin",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: JSON.parse(userSession),
            },
          }
        );
        const data = await response.json();
        //    console.log({data})
        commit("set_fetch_cart_data", data.numberOfCartItems);
      } catch (error) { }
    },

    async validate_Token({ commit }, userSession) {
      try {
        const response = await fetch(apiURL.validateToken, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: JSON.parse(userSession),
          },
        });
        const data = await response.ok;
        // console.log({data})
        if (data) {
          commit("set_validate_Token_data", data);
        } else {
          commit("set_validate_Token_data", 0);
        }
      } catch (error) { }
    },

    async fetchSignUpBonusStatus({ commit }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/v1/getBonusStatus",
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        const data = await response.json();
        commit("set_signUp_Bonus_data", data.signUpBonusStatus);
      } catch (error) {
      }
    },

    // Action for getting device intelligence
    async fetchDeviceIntelligence({ commit }) {
      const shield = new Shield();
      const siteId = '143810a9c227ea3caa6997f4379fa7e2e3f2c436';
      const props = { siteId };

      try {
        await shield.init(props);
        const res = await shield.getDeviceIntelligence();
        commit('setSessionId', res.result.session_id);
        commit('setDeviceIntelligence', res.result.device_intelligence);
      } catch (err) {
        console.error('Error fetching device intelligence:', err);
      }
    },

  },

  modules: {
    authTracking: {
      namespaced: true,
      ...authTracking
    }
  },
});