// Authentication tracking store module
const state = () => ({
  authVersion: "unauthenticated",
  authMethod: "unauthenticated"
});

const mutations = {
  setAuthVersion(state, version) {
    state.authVersion = version;
  },
  setAuthMethod(state, method) {
    state.authMethod = method;
  },
  resetAuth(state) {
    state.authVersion = "unauthenticated";
    state.authMethod = "unauthenticated";
  }
};

const actions = {
  trackAuthStart({ commit }, { version, method }) {
    // Validate version and method
    const validVersions = ["V1", "V2"];
    const validMethods = ["password", "google", "facebook"];
    
    const finalVersion = validVersions.includes(version) ? version : "V1";
    const finalMethod = validMethods.includes(method) ? method : "password";

    commit("setAuthVersion", finalVersion);
    commit("setAuthMethod", finalMethod);
    
    // Track authentication start
    window.gtag?.('event', 'authenticated_begin', {
      auth_version: finalVersion,
      auth_method: finalMethod
    });
  },

  trackAuthEnd({ state, commit }) {
    // Track authentication end with current state
    window.gtag?.('event', 'authenticated_ended', {
      auth_version: state.authVersion,
      auth_method: state.authMethod
    });
    
    // Reset to unauthenticated state
    commit("resetAuth");
    
    // Update global gtag parameters
    window.gtag?.('set', {
      auth_version: "unauthenticated",
      auth_method: "unauthenticated"
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
