<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "App",

  computed: {
    ...mapState('authTracking', ['authVersion', 'authMethod'])
  },

  watch: {
    authVersion(newValue) {
      this.$gtag.set({ auth_version: newValue });
    },
    authMethod(newValue) {
      this.$gtag.set({ auth_method: newValue });
    }
  },

  created() {
    // Set initial values
    this.$gtag.set({
      auth_version: this.authVersion,
      auth_method: this.authMethod
    });
  }
};
</script>
